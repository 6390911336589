<template>
  <div class="page">
    <div class="container">
      <div class="tips-container">
        <div class="tips-box">
          <p>请在菜单中选择在浏览器中打开</p>
          <img src="../../assets/among/arrow.svg" alt="">
        </div>
        <img src="../../assets/among/tips.png" >
      </div>
    </div>

  </div>

</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      isWx:1,
      payUrl:"",

    }
  },
  mounted() {
    this.isMobile()
  },
  methods: {
    isMobile() {
      let a = window.location.href.indexOf("payUrl=")
      let l = window.location.href.substring(a+7,window.location.href.length)
      //是否是微信浏览器
      if (! /(micromessenger)/i.test(navigator.userAgent)) {
        location.href = unescape(l);
      }
    },
  },

}
</script>

<style lang="css" scoped>
@import './pay.css';
@import './public.css';
</style>
